import { Options } from "vue-class-component";
import { ValueType } from "../form/ComponentsConfigObject";
import Node from "./nodes/Node.vue";
import BaseFunction from "@/mixins/BaseFunction";

@Options({
    name: "flowable-nodes-mixins",
    components: { Node },
    props: {
        config: {
            type: Object,
            default: (): any => {
                return {};
            },
        },
    },
})
export default class NodesMixins extends BaseFunction {
    public config: any = {};

    get ValueType() {
        return ValueType;
    }
    get groupNames() {
        return ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];
    }
    get setup(): any {
        return this.$store.getters["flowable/design"];
    }
    set setup(val: any) {
        this.$store.dispatch("flowable/setDesign", val).then();
    }
    get diagramMode(): any {
        return this.$store.getters["flowable/diagramMode"];
    }
    set diagramMode(val: any) {
        this.$store.dispatch("flowable/setDiagramMode", val).then();
    }
    get formItems(): any {
        return this.setup.formItems;
    }
    set formItems(val: any) {
        this.setup.formItems = val;
    }
}
